import moment from 'moment';
import React, { memo, useCallback, useEffect, useState } from 'react';
import { useContextMenu } from 'react-contexify';
import { useTranslation } from 'react-i18next';
import { useDispatch } from 'react-redux';
import { Badge } from 'reactstrap';
import uuid from 'uuid/v4';
import { doneOffline } from '../../../../redux/actions/chat';
import Avatar from '../Avatar/Avatar';
import { MENU_ID } from './ChatDialog';
import s from './ChatMessage.module.scss';
import ChatMessageAttachment from './ChatMessageAttachment';

const ChatMessage = memo(
    ({
        size,
        showStatus,
        message,
        showAvatar,
        owner,
        inEdit,
        onEditComplete,
        setImageFullscreen,
        operators,
        seen,
        setWordPreview,
        last,
        hasOffline,
        user,
    }) => {
        const { t } = useTranslation('chat');
        const { show } = useContextMenu({
            id: MENU_ID,
        });
        
        const { text, roomId, attachments } = message;

        /** Redux */
        const dispatch = useDispatch();

        /** State */
        const [messageText, setMessageText] = useState(text);
        const [htmlContent, setHtml] = useState();
        const [attachs] = useState(attachments ?? []);
        // const [messageUser, setMessageUser] = useState({});

        /** Fn */

        const initHtml = useCallback(() => {
            if (!text) {
                return '';
            }

            const r = /(?:www|https|([+]?[(]?[0-9]{3}[)]?[-s.]?[0-9]{3}[-s.]?[0-9]{4,6})?)[^s]+/g;
            const links = text.match(r);
            if (!links) return text;

            let html = text.replace(
                /(\b(https?|ftp):\/\/[-A-Z0-9+&@#/%?=~_|!:,.;]*[-A-Z0-9+&@#/%=~_|])/gim,
                '<a href="$1" target="_blank">$1</a>'
            );
            html = html.replace(
                /([+]?[(]?[0-9]{3}[)]?[-s.]?[0-9]{3}[-s.]?[0-9]{4,6})/gim,
                `<span class="phone-tag">$1<span class="d-none message-popover"><button data-room="${roomId}" data-body="$1" class="phone-pop-button btn btn-info btn-sm">${t(
                    'Додати телефон'
                )}</button></span></span>`
            );

            html = html.replace(
                /([a-zA-Z0-9._-]+@[a-zA-Z0-9._-]+\.[a-zA-Z0-9_-]+)/gim,
                `<span class="phone-tag">$1<span class="d-none message-popover"><button data-room="${roomId}" data-body="$1" class="email-pop-button btn btn-info btn-sm">${t(
                    'Додати email'
                )}</button></span></span>`
            );

            console.log('html');

            return html;
        }, [text, t, roomId]);

        function handleContextMenu(event) {
            if (message.kind !== 'action') {
                event.preventDefault();
                show(event, {
                    props: {
                        message,
                    },
                });
            }
        }

        const saveMessage = () => {
            if (typeof onEditComplete === 'function') {
                onEditComplete({ ...message, text: messageText });
            }
        };

        /** Effects */

        // useEffect(() => {
        //     if (operators && operators.length > 0) {
        //         const operator = operators.find((i) => i.operatorId === message.from);
        //         if (operator) {
        //             setMessageUser(operator);
        //         }
        //     }
        // }, [operators, message]);

        useEffect(() => {
            setHtml(initHtml);
        }, [initHtml]);

        /**
         * Renders
         */

        const messageDate = (message) => {
            if (moment(message.created).isBefore(moment().subtract(1, 'day')))
                return <span className="p-1">{moment(message.created).format('HH:mm DD.MM.YY')}</span>;
            return <span className="p-1">{moment(message.created).format('HH:mm')}</span>;
        };

        const renderPill = () => {
            if (message.kind === 'standart' || !message.kind) return null;

            switch (message.kind) {
                case 'offline':
                    return (
                        <>
                            <Badge className="ml-3" pill>
                                {t('Офлайн повідомлення')}
                            </Badge>
                            {hasOffline && (
                                <Badge
                                    onClick={() => {
                                        dispatch(doneOffline(message.roomId));
                                    }}
                                    pill
                                    className="ml-2 cursor-pointer"
                                    color="info"
                                >
                                    {t('відмітити вирішеним')}
                                </Badge>
                            )}
                        </>
                    );
                case 'routine':
                    return (
                        <Badge className="ml-3" pill>
                            Routine/Command
                        </Badge>
                    );
                default:
                    break;
            }
            return null;
        };

        return (
            <div
                className={`chatMessageWrapper ${s.chatMessage} ${owner ? s.owner : ''} ${
                    inEdit ? s.inEdit : ''
                } ${message.kind ? s[message.kind] : s.standart}`}
            >
                <div className={s.username}>{message.messageContext.name}</div>
                {showAvatar && (message.kind === 'standart' || !message.kind) ? (
                    <>
                        <div className={`${s.messageAvatar}`}>
                            <Avatar
                                user={{ ...message.messageContext }}
                                size={size}
                                showStatus={showStatus}
                            />
                        </div>
                    </>
                ) : null}
                {inEdit ? (
                    <div className={s.messageBody}>
                        <div className="text-right">
                            <i
                                onClick={() => onEditComplete(false)}
                                className={`mr-3 fa fa-close text-danger`}
                            ></i>
                            <i onClick={saveMessage} className={`fa fa-check  text-success`}></i>
                        </div>
                        <textarea
                            name="messageText"
                            id="messageText"
                            cols="46"
                            rows="2"
                            value={messageText}
                            style={{ overflowWrap: 'anywhere' }}
                            onChange={(e) => setMessageText(e.target.value)}
                        ></textarea>
                    </div>
                ) : (
                    <div
                        onContextMenu={handleContextMenu}
                        className={'messageBodyWrapper' + (message.kind === 'action' ? 'w-100' : '')}
                    >
                        {htmlContent ? (
                            <div
                                className={s.messageBody + (message.kind === 'action' ? ' ' + s.action : '')}
                            >
                                {htmlContent.split('\n').map((item, i) => (
                                    <span
                                        key={i}
                                        dangerouslySetInnerHTML={{ __html: item }}
                                        style={{ marginBottom: 8, overflowWrap: 'anywhere' }}
                                    ></span>
                                ))}
                                {/* {message.text} */}
                            </div>
                        ) : (
                            ''
                        )}
                    </div>
                )}

                {message.quick_replies && (
                    <div className={`mb-1 ${s.quickReplyHolder}`}>
                        {message.quick_replies.map((item, id) => {
                            return (
                                <span className={s.quickReply} key={uuid()}>
                                    {item.title}
                                </span>
                            );
                        })}
                    </div>
                )}
                {attachs.map((attachment, j) => (
                    <ChatMessageAttachment
                        key={`${message._id}_${j}`}
                        attachment={attachment}
                        setImageFullscreen={setImageFullscreen}
                        owner={owner}
                        setWordPreview={setWordPreview}
                    />
                ))}

                <small
                    className={
                        'd-block text-muted mb-2' + (message.kind === 'action' ? ' ' + s.actionTime : '')
                    }
                >
                    {messageDate(message)}
                    {seen && owner ? (
                        <span>
                            <i className="la la-check la-lg text-success ml-1"></i>
                        </span>
                    ) : null}
                    {renderPill()}
                </small>
            </div>
        );
    }
);

export default ChatMessage;
